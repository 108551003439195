import React from 'react'

import { RecordLinksAttributeDisplay } from 'features/views/attributes/RecordLinksAttributeDisplay'

import { useRecordLinksCellState } from './hooks/useRecordLinksCellState'
import { BaseCell } from './BaseCell'
import { TableViewCellComponent } from './types'

const MAX_RECORD_LINKS = 2
const MAX_LINES = 1

type RecordLinksCellProps = React.ComponentPropsWithoutRef<typeof BaseCell> & {}

export const RecordLinksCell: TableViewCellComponent<string | string[], RecordLinksCellProps> = ({
    value,
    column,
    isLoading,
    ...props
}) => {
    const { field } = column

    const { dereferencedRecords, isEmpty, columnRef, showOnlyCount } = useRecordLinksCellState({
        field,
        value,
        isLoading,
    })

    return (
        <BaseCell ref={columnRef} column={column} isEmpty={isEmpty} {...props}>
            <RecordLinksAttributeDisplay
                size={{
                    mobile: 's',
                    tablet: 'm',
                }}
                value={value}
                field={field}
                maxLinks={MAX_RECORD_LINKS}
                dereferencedRecords={dereferencedRecords}
                maxLines={MAX_LINES}
                isLoading={isLoading}
                showOnlyCount={showOnlyCount}
            />
        </BaseCell>
    )
}
