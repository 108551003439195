import React from 'react'

import * as Parts from 'features/views/ListView/TableView/TableView.parts'
import { TableViewCellContent } from 'features/views/ListView/TableView/TableViewCellContent'
import { TableViewCommentCount } from 'features/views/ListView/TableView/TableViewCommentCount'
import { TableViewThumbnail } from 'features/views/ListView/TableView/TableViewThumbnail'
import { TableViewColumn } from 'features/views/ListView/TableView/types'

type BaseCellRef = HTMLDivElement

type BaseCellProps = React.ComponentPropsWithoutRef<typeof Parts.Cell> & {
    column: TableViewColumn
    isEmpty?: boolean
}

const BaseCellInner = React.forwardRef<BaseCellRef, BaseCellProps>(function BaseCell(
    { children, column, isEmpty, style, ...props },
    ref
) {
    const { isPinned, isPrimary, minWidth } = column

    return (
        <Parts.Cell
            fontFamily="body"
            fontSize={{ mobile: 'bodyS', tablet: 'bodyM' }}
            fontWeight={isPrimary ? 'bodyBold' : 'bodyRegular'}
            lineHeight={{ mobile: 'bodyS', tablet: 'bodyM' }}
            color="text"
            isPinned={isPinned}
            style={{
                minWidth: `${minWidth}px`,
                ...style,
            }}
            ref={ref}
            {...props}
        >
            <TableViewCellContent column={column} isEmpty={isEmpty}>
                {isPrimary && <TableViewThumbnail mr="xl" />}
                {children}
                {isPrimary && <TableViewCommentCount ml="m" />}
            </TableViewCellContent>
        </Parts.Cell>
    )
})

export const BaseCell = React.memo(BaseCellInner)
