import React from 'react'

import { AttachmentsAttributeDisplay } from 'features/views/attributes/AttachmentsAttributeDisplay'
import { AttachmentValue } from 'features/views/attributes/types'

import { useAttachmentsCellState } from './hooks/useAttachmentsCellState'
import { BaseCell } from './BaseCell'
import { TableViewCellComponent } from './types'

const MAX_ATTACHMENTS = 2
const MAX_LINES = 1

type AttachmentsCellProps = React.ComponentPropsWithoutRef<typeof BaseCell> & {}

export const AttachmentsCell: TableViewCellComponent<AttachmentValue[], AttachmentsCellProps> = ({
    value,
    column,
    isLoading,
    ...props
}) => {
    const { field, rawOptions } = column

    const { columnRef, isEmpty, showOnlyCount } = useAttachmentsCellState({
        field,
        value,
        isLoading,
    })

    return (
        <BaseCell ref={columnRef} column={column} isEmpty={isEmpty} {...props}>
            <AttachmentsAttributeDisplay
                size={{
                    mobile: 's',
                    tablet: 'm',
                }}
                value={value}
                field={field}
                maxAttachments={MAX_ATTACHMENTS}
                maxLines={MAX_LINES}
                displayAsImage={rawOptions.displayAsImage}
                imageAltText={rawOptions.imageAltText}
                isLoading={isLoading}
                maxWidth="full"
                minWidth={0}
                showOnlyCount={showOnlyCount}
            />
        </BaseCell>
    )
}
