import { useCallback, useMemo, useState } from 'react'

import {
    convertV2FieldColorToTheme,
    getDefaultDropdownFieldOptionColorName,
} from 'utils/fieldUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { truncateText } from 'ui/helpers/utilities'

type UseDropdownAttributeDisplayStateProps = {
    field: FieldDto
    value?: string | string[]
    maxOptions?: number
    maxItemLength?: number
    isLoading?: boolean
    showOnlyCount?: boolean
}

type OptionValue = {
    label: string
    value: string
    color: string
}

export function useDropdownAttributeDisplayState({
    field,
    value,
    maxOptions,
    maxItemLength,
    isLoading,
    showOnlyCount = false,
}: UseDropdownAttributeDisplayStateProps) {
    const memoizedField = useDeepEqualsMemoValue(field)
    const memoizedValue = useDeepEqualsMemoValue(
        isLoading ? getPlaceholderValue(memoizedField) : value
    )

    const options = useMemo(() => {
        if (!memoizedValue) return []

        return convertValuesToOptionValues(memoizedField, memoizedValue, maxItemLength)
    }, [memoizedValue, memoizedField, maxItemLength])

    const limitedOptions = useMemo(() => {
        if (showOnlyCount) return []
        if (!maxOptions || options.length <= maxOptions) return options

        return options.slice(0, maxOptions)
    }, [maxOptions, options, showOnlyCount])

    const overflowingOptions = useMemo(() => {
        if (showOnlyCount) return options
        if (!maxOptions || options.length <= maxOptions) return []

        return options.slice(maxOptions)
    }, [maxOptions, options, showOnlyCount])

    const overflowingOptionsCountLabel = formatOverflowLabel(overflowingOptions, showOnlyCount)

    const [isOverflowPopupOpen, setOverflowPopupOpen] = useState(false)

    const onOverflowLabelMouseEnter = useCallback(() => {
        setOverflowPopupOpen(true)
    }, [])

    const onOverflowLabelMouseLeave = useCallback(() => {
        setOverflowPopupOpen(false)
    }, [])

    const onOverflowLabelFocus = useCallback(() => {
        setOverflowPopupOpen(true)
    }, [])

    const onOverflowLabelBlur = useCallback(() => {
        setOverflowPopupOpen(false)
    }, [])

    const columnCount = options.length
    let gridTemplateColumns = `repeat(${columnCount}, auto)`
    if (!!overflowingOptionsCountLabel) {
        gridTemplateColumns = `repeat(${columnCount}, auto) max-content`
    }

    return useMemo(
        () => ({
            options: limitedOptions,
            overflowingOptionsCountLabel,
            overflowingOptions,
            isOverflowPopupOpen,
            onOverflowPopupOpenChange: setOverflowPopupOpen,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowLabelFocus,
            onOverflowLabelBlur,
            gridTemplateColumns,
        }),
        [
            limitedOptions,
            overflowingOptionsCountLabel,
            overflowingOptions,
            isOverflowPopupOpen,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowLabelFocus,
            onOverflowLabelBlur,
            gridTemplateColumns,
        ]
    )
}

function convertValuesToOptionValues(
    field: FieldDto,
    value: string | string[],
    maxItemLength?: number
): OptionValue[] {
    const options = field.options?.options ?? []
    if (options.length < 1) return []

    const fieldOptionsMap = options.reduce(
        (acc, option) => acc.set(option.value!, option),
        new Map<string, FieldOptionsOptionItem>()
    )

    const valueList = Array.isArray(value) ? value : [value]
    valueList.sort((a, b) => a.localeCompare(b))

    return valueList.reduce((acc, value) => {
        const option = fieldOptionsMap.get(value)

        const effectiveValue = option?.value || value

        let label = option?.label || effectiveValue
        if (maxItemLength) {
            label = truncateText(label, maxItemLength)
        }

        let color = getDefaultDropdownFieldOptionColorName()
        if (option?.color) {
            // This will try to map the old color format to the new one.
            // If the color is not found, it means that this is a new color,
            // so use it as is.
            color = convertV2FieldColorToTheme(option.color)
        }

        return [
            ...acc,
            {
                value: effectiveValue,
                label,
                color,
            },
        ]
    }, [])
}

function getPlaceholderValue(field: FieldDto): string[] {
    if (field.type === 'dropdown') return ['placeholder']

    return ['placeholder', 'placeholder-2', 'placeholder-3', 'placeholder-4']
}

function formatOverflowLabel(overflowingOptions: OptionValue[], showOnlyCount?: boolean) {
    if (showOnlyCount && !!overflowingOptions.length) {
        return overflowingOptions.length.toString()
    }

    if (!overflowingOptions?.length) {
        return ''
    }

    return `+${overflowingOptions.length}`
}
