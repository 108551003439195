import React from 'react'

import { UserAttributeDisplay } from 'features/views/attributes/UserAttributeDisplay'

import { BaseCell } from './BaseCell'
import { TableViewCellComponent } from './types'

const MAX_LINES = 1

type UserCellProps = React.ComponentPropsWithoutRef<typeof BaseCell> & {}

export const UserCell: TableViewCellComponent<UserRefDto, UserCellProps> = ({
    value,
    column,
    isLoading,
    ...props
}) => {
    const { field } = column

    const isEmpty = !isLoading && !value

    return (
        <BaseCell column={column} isEmpty={isEmpty} {...props}>
            <UserAttributeDisplay
                size={{
                    mobile: 's',
                    tablet: 'm',
                }}
                value={value}
                field={field}
                maxLines={MAX_LINES}
                isLoading={isLoading}
                trim
                maxWidth="full"
                minWidth={0}
            />
        </BaseCell>
    )
}
