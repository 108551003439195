import { useLayoutEffect, useMemo, useRef, useState } from 'react'

const WIDTH_TO_SHOW_COUNT = 200 // px

type UseDropdownCellStateProps = {
    field: FieldDto
    value?: string | string[]
    isLoading?: boolean
}

export function useDropdownCellState(options: UseDropdownCellStateProps) {
    const { field, value, isLoading } = options

    const isEmpty = !isLoading && (!value || (Array.isArray(value) && value.length < 1))

    const columnRef = useRef<HTMLDivElement>(null)

    const [showOnlyCount, setShowOnlyCount] = useState(false)

    useLayoutEffect(() => {
        const column = columnRef.current
        if (!column) return

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const { width } = entry.contentRect
                setShowOnlyCount(width < WIDTH_TO_SHOW_COUNT)
            }
        })

        if (field.type === 'multi_select') {
            resizeObserver.observe(column)
        }

        return () => {
            resizeObserver.disconnect()
        }
    }, [field.type])

    return useMemo(
        () => ({
            columnRef,
            isEmpty,
            showOnlyCount,
        }),
        [isEmpty, showOnlyCount]
    )
}
