import { TableViewColumn } from './types'

function getColumnMinWidth(column: TableViewColumn): number {
    switch (column.field.type) {
        case 'percentage':
            if (column.rawOptions.percentageDisplay === 'percentBar') {
                return 180
            }
            return 100
        default:
            return 100
    }
}

function getColumnMaxWidth(column: TableViewColumn): number | undefined {
    switch (column.field.type) {
        default:
            return 600
    }
}

function getColumnResizeMinWidth(column: TableViewColumn): number {
    switch (column.field.type) {
        case 'percentage':
            if (column.rawOptions.percentageDisplay === 'percentBar') {
                return 180
            }
            return 100
        default:
            return 100
    }
}

function getColumnResizeMaxWidth(column: TableViewColumn): number | undefined {
    switch (column.field.type) {
        default:
            return undefined
    }
}

export function setColumnWidths(columns: TableViewColumn[]): TableViewColumn[] {
    const newColumns = columns.map((column) => {
        column.minWidth = getColumnMinWidth(column)
        column.maxWidth = getColumnMaxWidth(column)
        column.resizeMinWidth = getColumnResizeMinWidth(column)
        column.resizeMaxWidth = getColumnResizeMaxWidth(column)
        return column
    })

    return newColumns
}
