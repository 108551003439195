import React from 'react'

import { DropdownAttributeDisplay } from 'features/views/attributes/DropdownAttributeDisplay'

import { useDropdownCellState } from './hooks/useDropdownCellState'
import { BaseCell } from './BaseCell'
import { TableViewCellComponent } from './types'

const MAX_OPTIONS = 3
const MAX_LINES = 1

type DropdownCellProps = React.ComponentPropsWithoutRef<typeof BaseCell> & {}

export const DropdownCell: TableViewCellComponent<string | string[], DropdownCellProps> = ({
    value,
    column,
    isLoading,
    ...props
}) => {
    const { field } = column

    const { columnRef, isEmpty, showOnlyCount } = useDropdownCellState({ field, value, isLoading })

    return (
        <BaseCell ref={columnRef} column={column} isEmpty={isEmpty} {...props}>
            <DropdownAttributeDisplay
                size={{
                    mobile: 's',
                    tablet: 'm',
                }}
                value={value}
                field={field}
                maxOptions={MAX_OPTIONS}
                maxLines={MAX_LINES}
                isLoading={isLoading}
                showOnlyCount={showOnlyCount}
            />
        </BaseCell>
    )
}
